import React, { Component } from 'react'
import { MapContext } from "../Map";
export default class MapSwitcher extends Component {
    constructor(props) {
        super(props);
        let view = 2;
        if (window.location.pathname === "/planification") {
            view = 2;
        }
        this.state = {
            view: view
        };

    }

    componentDidMount = () => {
        this.toggleMenu();
    }

    toggleMenu = () => {
        this.context.getLayers().forEach(layer => {
            if (["urbis"].includes(layer.get("title"))) {
                layer.setOpacity(0.5);
            }
            if (this.state.view === 1) {
                if (!["Chantiers de Bruxelles Mobilité", "Itinéraires cycable conseillé (ICR)"].includes(layer.get("title"))) {
                    layer.setVisible(true);
                    layer.setOpacity(1);
                }
            } else if (this.state.view === 2) {
                if ([
                    "Itinéraires cycable conseillé (ICR)",
                    "Zones à forte concentration d'accidents",
                    "Article 33",
                    "Voiries régionales",
                    "Chantiers des séances précédentes"].includes(layer.get("title"))) {
                    layer.setVisible(false);
                }
            }
            // else {
            //     if (["UrbIS",
            //         "Itinéraires cycable conseillé (ICR)",
            //         "Zones à forte concentration d'accidents"].includes(layer.get("title"))) {
            //         layer.setVisible(false);
            //     } else if (layer.get("title") === "Chantiers" || layer.get("title") === "Mailles") {
            //         layer.setOpacity(0);
            //     }
            // }
        });
        this.state.view === 2 ? this.setState({ view: 1 }) : this.setState({ view: this.state.view + 1 })
    }
    render() {
        return (
            <div className="btn-map-switcher">
                <button
                    className="btn btn-outline-secondary gate__btn--menu"
                    type="button"
                    onClick={this.toggleMenu}
                >
                    <i className="fa fa-map" />
                </button>
            </div>
        )
    }
}
MapSwitcher.contextType = MapContext;