import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "../containers/HomePage";
import PlanificationPage from "../containers/PlanificationPage";
import ProgrammationPage from "../containers/ProgrammationPage";

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" component={HomePage} exact={true} />
      <Route path="/planification" component={PlanificationPage} exact={true} />
      <Route path="/programmation" component={ProgrammationPage} exact={true} />
    </Switch>
  </BrowserRouter>
);
export default AppRouter;
