import React, { Component } from "react";
import Home from "../components/Home";
import Header from "../components/layouts/Header";

export default class HomePage extends Component {
  render() {
    return (
      <div className="home__container">
        <Header history={this.props.history} />
        <Home history={this.props.history} />
      </div>
    );
  }
}
