// GET_ATTRIBUTES
// import { SET_SELECTED_ATTRIBUTES } from "./Types";
import { GET_WORKSITES_SUCCESS, GET_WORKSITES_FAILURE } from "./Types";
import { getWorksitesAPI } from "../API/API";

// Get all clients
const getWorksitesSuccess = worksites => {
  // worksites.features.sort((a, b) =>
  //   a.properties.cluster_nb > b.properties.cluster_nb ? 1 : -1
  // );
  return {
    type: GET_WORKSITES_SUCCESS,
    worksites
  };
};

const getWorksitesFailure = error => {
  return {
    type: GET_WORKSITES_FAILURE,
    error
  };
};

export const getWorksites = (url) => dispatch => {
  return getWorksitesAPI(url).then(
    response => dispatch(getWorksitesSuccess(response.data)),
    error => dispatch(getWorksitesFailure(error))
  );
};

// export const setSelectedClusters = (item = "") => {
//   return dispatch => {
//     return dispatch({
//       type: SET_SELECTED_CLUSTERS,
//       item
//     });
//   };
// };
