import React, { Component } from "react";
import logo from "../../logo.png";
import logoMin from "../../logo-min.png";
import { authContext } from "../../AdalConfig";
export default class Header extends Component {
  go2Home = () => {
    this.props.history.push("/");
  };

  displayUserInformation() {
    return (
      <div className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle user__dropdown"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {authContext._user.profile.email}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            className="dropdown-item"
            href="/"
            onClick={() => {
              authContext.logOut();
            }}
          >
            Logout
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className={
          window.location.pathname == "/"
            ? "header-container"
            : "header-container--viewer"
        }
      >
        <nav className="navbar navbar-dark pt-3 pb-3">
          <img src={logo} alt="logo" onClick={this.go2Home} />
          {this.displayUserInformation()}
        </nav>
      </div>
    );
  }
}
