import axios from "axios";
// Clusters
export const getClustersAPI = (url) => {
  const request = axios.get(url);
  request
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
    });
  // return promise
  return request;
};

// Worksites
export const getWorksitesAPI = (url) => {
  const request = axios.get(url);
  request
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error);
    });
  // return promise
  return request;
};
