import React, { Component } from "react";
import Header from "../components/layouts/Header";
import Map from "../components/gis/Map";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import SearchTab from "../components/gis/tabs/SearchTab";
import AttributesTab from "../components/gis/tabs/AttributesTab";
import LayerSwitcher from "../components/gis/tools/LayerSwitcher";
import CockpitTab from "../components/gis/tabs/CockpitTab";
import MapSwitcher from "../components/gis/tools/MapSwitcher";

import { authContext } from "../AdalConfig";


export class PlanificationPage extends Component {
  render() {

    const appConfig = require("../config/app-config.json");
    //const mapConfig = require("../config/map.planification.json");
    const mapConfig = require("../config/map.planification.dev.json");

    let access = appConfig.access.planification.includes(authContext._user.profile.email);
    if (appConfig.access.admin.includes(authContext._user.profile.email)) {
      access = true;
    }
    return (
      <div>
        <Map mapConfig={mapConfig}>
          {access && (<SearchTab isAttributeTabVisible={true} mapConfig={mapConfig} />)}
          <MapSwitcher />
          <LayerSwitcher isAttributeTabVisible={true} />
          <CockpitTab history={this.props.history} mapConfig={mapConfig} />
          {this.props.layout.tab.includes("attributeTab") && access && <AttributesTab />}
        </Map>
      </div>
    );
  }
}

PlanificationPage.propTypes = {
  layout: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  layout: state.layout
});

export default connect(
  mapStateToProps,
  undefined
)(PlanificationPage);
