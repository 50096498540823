import {
  ADD_TAB,
  REMOVE_TAB,
  START_LOADING,
  FINISH_LOADING
} from "../actions/Types";

const initialState = {
  tab: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_TAB:
      return {
        ...state,
        tab: state.tab.length > 0 ? [...state.tab, action.tab] : [action.tab]
      };
    case REMOVE_TAB:
      return {
        ...state,
        tab: state.tab.filter(val => val !== action.index)
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case FINISH_LOADING:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
