import React from "react";
import "./App.scss";
import AppRouter from "./routers/AppRouter";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

function App() {
  const store = configureStore();
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App;
