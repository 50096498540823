import React, { Component } from "react";
import { initMap } from "./MapFactory";
import Select from "ol/interaction/Select";
import PropTypes from "prop-types";

import Loader from "../layouts/Loader";

import { addTab, startLoading } from "../../actions/LayoutAction";
import { setSelectedAttribute } from "../../actions/SelectedAttributeAction";

import { connect } from "react-redux";


export const MapContext = React.createContext(undefined);
export class Map extends Component {
  constructor(props) {
    super(props);
    this.mapConfig = this.props.mapConfig;
    // set loader
    this.props.startLoading();
    //  instantiate the map object from the config file
    this.olMap = initMap(this.mapConfig);
  }

  componentDidMount() {
    this.olMap.setTarget("map");
    this.addClickFeatureToMap();
  }

  addClickFeatureToMap = () => {
    var selectSingleClick = new Select();
    this.olMap.addInteraction(selectSingleClick);

    this.olMap.on(
      "singleclick",
      function (event) {
        this.olMap.forEachFeatureAtPixel(
          event.pixel,
          function (feature, layer) {
            if (layer && layer.get("title") === "Chantiers") {
              var selectedFeatureObj = {};
              Object.keys(this.mapConfig.attributes.keys).forEach(
                function (key) {
                  const keyDB = this.mapConfig.attributes.keys[key];
                  const valueViewer = feature.get(keyDB);
                  selectedFeatureObj[key] = valueViewer;
                }.bind(this)
              );
              this.props.setSelectedAttribute(selectedFeatureObj);

              this.olMap.getInteractions().forEach((item) => {
                if (item.get("name") === "selectFeature") {
                  item.getFeatures().forEach(element => {
                    item.getFeatures().pop();
                  })
                  item.getFeatures().push(feature);
                }
              })
              this.props.addTab("attributeTab");
            }


          }.bind(this)
        );
      }.bind(this)
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading ? <Loader /> : ""}
        <div id="map" className="map-container">
          <MapContext.Provider value={this.olMap}>
            {this.props.children}
          </MapContext.Provider>
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  addTab: PropTypes.func.isRequired,
  setSelectedAttribute: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isLoading: state.layout.isLoading
});

const mapDispatchToProps = dispatch => ({
  addTab: tab => dispatch(addTab(tab)),
  setSelectedAttribute: item => dispatch(setSelectedAttribute(item)),
  startLoading: () => dispatch(startLoading())
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
