import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { createLogger } from "redux-logger";

import SelectedAttributeReducer from "../reducers/SelectedAttributeReducer";
import layoutReducer from "../reducers/LayoutReducer";
import ClustersReducer from "../reducers/ClustersReducer";
import WorksitesReducer from "../reducers/WorksitesReducer";

// export default () => {
//   const store = createStore(
//     combineReducers({
//       attributes: attributesReducer
//     })
//   );
//   return store;
// };

// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

const logger = createLogger({
  /* https://github.com/evgenyrodionov/redux-logger */
  collapsed: true,
  diff: true
});

const initialState = {};
const middleware = [thunk, logger];
const reducers = combineReducers({
  layout: layoutReducer,
  selectedAttribute: SelectedAttributeReducer,
  clusters: ClustersReducer,
  worksites: WorksitesReducer
});

let store;

if (window.navigator.userAgent.indexOf("Chrome")) {
  store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
} else {
  store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}

export default () => {
  return store;
};
