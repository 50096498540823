import React, { Component } from "react";

import { MapContext } from "../Map";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { removeTab } from "../../../actions/LayoutAction";

import AttributeItem from "../tabs/AttributeItem";

export class AttributesTab extends Component {

  pin = () => {
    // search in the worksite store for the feature
    let selectedWorksite = undefined;
    this.props.worksites.list.features.forEach(item => {
      if (item.properties.id_ws == this.props.selectedAttribute.selectedAttribute.B1) {
        selectedWorksite = item;
      }
    });
    if (selectedWorksite !== undefined) {
      // get the feature from source layer (to avoid the problem that the feature stored in the redux is not a OL feature)
      let selectedFeature;
      this.context.getLayers().forEach(layer => {
        if (layer.get("title") === "Chantiers") {
          selectedFeature = layer.getSource().getFeatureById(selectedWorksite.id);
        }

      });
      this.context.getView().fit(selectedFeature.getGeometry().getExtent());
    }
  }

  closeTab = () => {
    this.context.getInteractions().getArray().forEach(element => {
      if (element.constructor.name == "Select") {
        element.getFeatures().clear();
      }
    });
    this.props.removeTab("attributeTab");
  };

  render() {
    const AttributesOjb = this.props.selectedAttribute;
    let attributes = null;
    if (AttributesOjb.selectedAttribute) {
      const attributeItem = AttributesOjb.selectedAttribute;
      attributes = Object.keys(attributeItem).map(function (key) {
        let value = undefined;
        if (attributeItem[key] == null) {
          value = "/";
        } else {
          value = attributeItem[key].toString().split(",").join(", ");
        }
        if (key.includes("Date") && attributeItem[key] != null) {
          value = attributeItem[key].toString().replace(/^(\d{4})-(\d{2})-(\d{2})Z$/, "$3/$2/$1");
        }
        return <AttributeItem label={key} value={value} key={key} />;
      });
    }
    return (
      <div className="attribute-tab__panel">
        <div className="attribute-tab__header">
          <div className="attribute-tab__header-atlas">
            <h5>Chantier</h5>
          </div>
          <div className="attribute-tab__pin">
            <button
              type="button"
              className="attribute-tab__pin-btn"
              onClick={this.pin}
            >
              <i className="fa fa-map-pin" />
            </button>
          </div>
          <div className="attribute-tab__close">
            <button
              className="attribute-tab__close-btn"
              onClick={this.closeTab}
            >
              X
            </button>
          </div>
        </div>
        <hr />
        <table className="attributes__container">{attributes}</table>
      </div>
    );
  }
}

AttributesTab.propTypes = {
  removeTab: PropTypes.func.isRequired,
  selectedAttribute: PropTypes.object.isRequired,
  worksites: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedAttribute: state.selectedAttribute,
  worksites: state.worksites
});

const mapDispatchToProps = dispatch => ({
  removeTab: item => dispatch(removeTab(item))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributesTab);

AttributesTab.contextType = MapContext;
