import { SET_SELECTED_ATTRIBUTES } from "../actions/Types";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_ATTRIBUTES:
      return {
        ...state,
        selectedAttribute: action.item
      };
    default:
      return state;
  }
}
