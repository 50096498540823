// GET_ATTRIBUTES
import { SET_SELECTED_ATTRIBUTES } from "./Types";

export const setSelectedAttribute = (item = "") => {
  return dispatch => {
    return dispatch({
      type: SET_SELECTED_ATTRIBUTES,
      item
    });
  };
};
