import React, { Component } from "react";
import Header from "../../layouts/Header";

// redux
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getClusters } from "../../../actions/ClustersAction";
import { getWorksites } from "../../../actions/WorksitesAction";
import { finishLoading } from "../../../actions/LayoutAction";
import Select from "ol/interaction/Select";

// openlayers
import { MapContext } from "../Map";
import MultiPolygon from "ol/geom/MultiPolygon";
import Polygon from "ol/geom/Polygon";
import { Stroke, Style, Fill, Text, Circle, RegularShape } from "ol/style.js";
import { pointerMove } from "ol/events/condition";
import Point from "ol/geom/Point";
import { getCenter } from 'ol/extent';
import Legend from "ol-ext/control/Legend";
import VectorSource from "ol/source/Vector.js";
import { Vector as VectorLayer } from "ol/layer.js";
import Feature from "ol/Feature";
import Mask from 'ol-ext/filter/Mask';
// tabs item
import AttributeItem from "../tabs/AttributeItem";

// map factory + OL for overview
import { createLayer } from "../MapFactory";
import { OverviewMap } from 'ol/control';
import Projection from "ol/proj/Projection.js";
import OlView from "ol/View";

export class CockpitTab extends Component {
  constructor(props) {
    super(props);
    this.mapConfig = this.props.mapConfig;
    this.state = {
      clusters: [],
      selectedCluster: null,
      worksiteOfCluster: [],
      attributesWorksiteOfCluster: this.mapConfig.clusters.keysWs
    };

    // create a layer to set the selected cluster in a different color
    let sourceCluster = new VectorSource();

    this.vectorCluster = new VectorLayer({
      title: "Maille sélectionnée",
      source: sourceCluster,
      style: new Style({
        stroke: new Stroke({
          color: "rgb(35,35,35,0.9)",
          width: 5,
          lineDash: [8, 8],
          lineCap: "square"
        })
      }),
      declutter: true
    });

    // create layer to set worksite of selected cluster in other size for visibility
    let sourceWorksite = new VectorSource();
    let sourceWorksiteLabels = new VectorSource();

    let styleSelectedWoksites = (feature) => {
      let style = this.mapConfig.layers[this.mapConfig.layers.length - 1].styleProgra;
      if (feature.get("status_type") == "AUTORISE") {
        style = this.mapConfig.layers[this.mapConfig.layers.length - 1].styleAuthorized;
      }
      return [
        new Style({
          stroke: new Stroke({
            color: style[0].color,
            width: style[0].width,
          }),
          fill: new Fill({
            color: style[1].color
          }),
        }),
      ]

    }

    let styleSelectedWoksitesLabels = (feature) => {
      let style = this.mapConfig.layers[this.mapConfig.layers.length - 1].styleProgra;
      if (feature.get("status_type") == "AUTORISE") {
        style = this.mapConfig.layers[this.mapConfig.layers.length - 1].styleAuthorized;
      }

      return [
        new Style({
          geometry: function (feature) {
            var coordinates = feature.getGeometry().getExtent();
            return new Point(getCenter(coordinates));
          },
          text: new Text({
            font: style[2].font,
            text: isNaN(feature.get("id_ws")) ? feature.get("id_ws") + " (" + feature.get("dura_open") + "j)\n" + feature.get("imp_abrev_fr")
              : feature.get("id_ws").toString() + " (" + feature.get("dura_open") + "j)\n" + feature.get("imp_abrev_fr"),
            fill: new Fill({
              color: style[2].fillColor
            }),
            stroke: new Stroke({ color: style[3].color, width: style[3].width }),
            placement: style[2].placement,
            overflow: style[2].overflow
          })
        })
      ]

    }
    this.vectorWorksite = new VectorLayer({
      title: "Chantier de la maille sélectionnée",
      source: sourceWorksite,
      style: styleSelectedWoksites
    })

    this.vectorWorksiteLabels = new VectorLayer({
      title: "Labels des chantiers de la mailles sélectionnées",
      source: sourceWorksiteLabels,
      style: styleSelectedWoksitesLabels
    })

  }


  componentDidMount() {
    // set overview map (in cockpit to set the targer in cockpit)
    // load config file
    const appConfig = this.props.mapConfig;

    // config projection
    const projection = new Projection({
      code: appConfig.map.projection.code,
      units: appConfig.map.projection.units,
      getPointResolution: function (resolution) {
        return resolution;
      }
    });
    // set layers overview
    let layerOverview = [];
    appConfig.overview.layers.forEach(function (arrayItem) {
      layerOverview.push(createLayer(arrayItem));
    });

    // set overview control
    const overviewMapControl = new OverviewMap({
      layers: layerOverview,
      view: new OlView({
        projection: projection,
        maxZoom: appConfig.overview.maxZoom,
        minZoom: appConfig.overview.minZoom,
        zoom: appConfig.overview.zoom,
        center: appConfig.overview.center,
        resolutions: [appConfig.overview.resolution],
      }),
      target: appConfig.overview.target,
      collapsed: appConfig.overview.collapsed,
      collapsible: appConfig.overview.collapsible,
      className: appConfig.overview.className,
      render: function (mapEvent) {
        // rewrite render function to avoir moving map
        this.context.getControls().forEach(item => {
          if (item instanceof OverviewMap) {
            item.updateBox_();
          }
        })
      }.bind(this)
    });

    // add control to map
    this.context.addControl(overviewMapControl);


    // get data content
    this.props.getWorksites(this.mapConfig.worksites.urlAPI)
      .then(() => this.props.getClusters(this.mapConfig.clusters.urlAPI))
      .then((response) => {
        return this.loadClusters(response)
      })
      .then((cluster) => {
        return this.getWorksiteOfClusters(cluster)
      })
      .then(() => {
        return this.hightlightWorksite(this.state.worksiteOfCluster);
      })
      .then(() => this.context.addLayer(this.vectorCluster))
      .then(() => this.context.addLayer(this.vectorWorksite))
      .then(() => this.context.addLayer(this.vectorWorksiteLabels))
      .then(() => {
        var style_selected = new Style({
          fill: new Fill({ color: [255, 255, 255, 0] })
        });
        var selectSingleClick = new Select({ style: style_selected });
        this.context.addInteraction(selectSingleClick);

        this.context.on(
          "singleclick",
          function (event) {
            let features = [];
            let clickFeature = false;
            let clickCluster = false;
            this.context.forEachFeatureAtPixel(
              event.pixel,
              function (feature, layer) {
                if (layer && layer.get("title") === "Chantiers") {
                  clickFeature = true;
                }
                else if (layer && layer.get("title") === "Mailles" && !clickFeature) {
                  let id = feature.get("cluster_order");
                  if (id !== this.state.selectedCluster.Cluster) {
                    clickCluster = true;
                    features.push(feature);
                  }
                }
              }.bind(this))
            if (clickCluster) {
              this.state.clusters.map((item, index) => {
                if (item.properties.cluster_order === features[0].get("cluster_order")) {
                  this.selectCluster(index);
                }
              })
            }
          }.bind(this)
        )
      })
      .then(() => {
        this.props.finishLoading()
      });
  }

  // setLegend() {
  //   // // legend control
  //   var legendControl = new Legend({
  //     title: 'Légende',
  //     style: undefined,
  //     collapsed: false
  //   });
  //   // // add row to the legend
  //   // let legendContent = buildStyleLegend(appConfig.legend);
  //   this.context.getLayers().forEach(layer => {
  //     if (layer.get('title') === "Chantiers") {
  //       console.log(layer.getSource().getFeatures()[0]);
  //       legendControl.addRow({ 'name': 'chantiers', 'feature': layer.getSource().getFeatures()[0] });
  //     }
  //   })
  //   console.log(legendControl);
  //   this.context.addControl(legendControl)
  // }

  // go on cluster on click

  // else if (layer.get("title") === "Clusters") {
  //   console.log(feature);
  //   // recenter map + ighlight the cluster on the map
  //   let clusterPolygon = new MultiPolygon(feature.getGeometry().getCoordinates());
  //   let extent = clusterPolygon.getExtent();
  //   this.centerOnCluster(extent);
  //   this.highlightCluster(clusterPolygon);

  //   this.maskViewOutOfCluster(clusterPolygon);
  // }

  loadClusters = (response) => {
    return new Promise((resolve, reject) => {
      // check if clusters
      if (!this.props.clusters.list.length > 0) {
        // create object with the good key name defined in the map.json
        let selectedFeatureObj = {};
        let feature = response.clusters.features[0];

        Object.keys(this.mapConfig.clusters.keys).forEach(
          function (key) {
            const keyDB = this.mapConfig.clusters.keys[key];
            const valueViewer = feature.properties[keyDB];
            selectedFeatureObj[key] = valueViewer;
          }.bind(this)
        );


        // save state
        this.setState({
          ...this.state,
          clusters: response.clusters.features,
          selectedCluster: selectedFeatureObj
        });

        // recenter map + ighlight the cluster on the map
        let clusterPolygon = this.setGeometryFeature(feature);
        let extent = clusterPolygon.getExtent();
        this.centerOnCluster(extent);
        this.highlightCluster(clusterPolygon);
        this.maskViewOutOfCluster(clusterPolygon);
        // resolve the promise with the id for the next 
        // then (get worksites in function of the cluster id)
        resolve(selectedFeatureObj)
      } else {
        reject("no clusters")
      }
    })
  }

  setGeometryFeature = (feature) => {
    if (feature.geometry.type == "MultiPolygon") {
      return new MultiPolygon(feature.geometry.coordinates);
    } else {
      return new Polygon(feature.geometry.coordinates);
    }
  }

  getWorksiteOfClusters = (cluster) => {
    return new Promise((resolve, reject) => {
      try {
        let idCluster;
        let worksiteOfCluster = [];
        if (!cluster["Post seance Y/N"]) {
          idCluster = cluster.Cluster;
          this.props.worksites.list.features.forEach((value, index) => {
            let arrayClusters = undefined;
            if (value.properties.status_type != "PLANIFIED") { // progra (numbers)
              arrayClusters = [value.properties.cluster_display];
            } else { // planif (list)
              arrayClusters = value.properties.cluster_nb.split(',').map(Number);
              //arrayClusters = [Number(value.properties.cluster_display)];
            }
            if (arrayClusters.includes(idCluster)) {
              worksiteOfCluster.push(value);
            }
          })
        } else {
          idCluster = cluster.Cluster;
          this.props.worksites.list.features.forEach((value, index) => {
            if (value.properties.cluster_post_seance === idCluster) {
              worksiteOfCluster.push(value);
            }
          })
        }
        worksiteOfCluster.sort((a, b) => (a.properties.dura_open < b.properties.dura_open) ? 1 : ((b.properties.dura_open < a.properties.dura_open) ? -1 : 0));

        this.setState({
          worksiteOfCluster
        });
        resolve("ok");
      } catch (e) {
        reject(e);
      }
    });
  }

  setSelectCluster = id => {
    if (isNaN(id)) { // when select via select of the cockpit -> return event
      const selectedIndex = id.target.options.selectedIndex;
      id = id.target.options[selectedIndex].getAttribute('data-key');
    }
    // first remove all selected clusters
    this.vectorCluster.getSource().clear();
    // // we add all the clusters to make the selectable to zoom to
    // this.state.clusters.forEach((element) => {
    //   let feature = new Feature(new MultiPolygon(element.geometry.coordinates));
    //   this.vectorCluster.getSource().addFeature(feature);
    // }
    // )
    this.vectorWorksite.getSource().clear();
    this.vectorWorksiteLabels.getSource().clear();

    // if first cluster and go previous
    // else if last cluster and go next
    if (id < 0) {
      id = this.state.clusters.length - 1;
    } else if (id >= this.state.clusters.length) {
      id = 0;
    }

    // search the selected cluster in the list of clusters
    // let selectCluster = this.state.clusters.find(cluster => {
    //   if (!(new RegExp('bis$')).test(id)) {
    //     return cluster.properties.cluster_nb === parseInt(id);
    //   } else {
    //     if (cluster.properties.post_seance_yn) {
    //       return cluster.properties.cluster_nb === parseInt(id);
    //     }
    //   }
    // });
    let selectCluster = this.state.clusters[id];


    // change the attribute key to the defines one in the map.json
    let selectedCluster = {};
    Object.keys(this.mapConfig.clusters.keys).forEach(
      function (key) {
        const keyDB = this.mapConfig.clusters.keys[key];
        const valueViewer = selectCluster.properties[keyDB];
        selectedCluster[key] = valueViewer;
      }.bind(this)
    );
    this.setState({
      selectedCluster
    });

    // set selected cluster in the state + center and highlight the map on the selected cluster
    let clusterPolygon = this.setGeometryFeature(selectCluster);
    let extent = clusterPolygon.getExtent();
    this.centerOnCluster(extent);
    this.highlightCluster(clusterPolygon);
    this.maskViewOutOfCluster(clusterPolygon);

    this.getWorksiteOfClusters(selectedCluster).then(() => {
      this.hightlightWorksite(this.state.worksiteOfCluster);
    })

  };

  centerOnCluster = extent => {
    // var x = extent[0] + (extent[2] - extent[0]) / 2;
    // var y = extent[1] + (extent[3] - extent[1]) / 2;
    // this.context.getView().setCenter([x, y]);
    // this.context.getView().setZoom(17);
    this.context.getView().fit(extent);
    if (this.context.getView().getZoom() > 15) {
      this.context.getView().setZoom(15);
    }
  };

  highlightCluster = polygon => {
    let feature = new Feature(polygon);
    this.vectorCluster.getSource().addFeature(feature);
  };

  hightlightWorksite = arrayOfWorksite => {
    arrayOfWorksite.forEach((element) => {
      let feature = new Feature(this.setGeometryFeature(element));
      feature.setProperties(element.properties);
      this.vectorWorksite.getSource().addFeature(feature);
      this.vectorWorksiteLabels.getSource().addFeature(feature);
    });
  }

  selectCluster = id => {
    this.setSelectCluster(id);
  };
  nextCluster = () => {
    // item.properties.post_seance_yn ? item.properties.cluster_post_seance : item.properties.cluster_nb
    // console.log(this.state.selectedCluster);
    // if (this.state.some(cluster, index => {
    //   cluster.Cluster === this.state.selectedCluster && this.state.selectedCluster["Post seance"] !== ""
    // })) {
    //   id = parseInt(this.state.clusters[id + 1].Cluster)
    // }
    let id;
    for (let index = 0, l = this.state.clusters.length; index < l; index++) {
      if (this.state.clusters[index].properties.cluster_order === this.state.selectedCluster["Ordre cluster"]
        && (this.state.clusters[index].properties.cluster_post_seance === this.state.selectedCluster["Post seance"])) {
        id = index + 1;
        break;
        // if (this.state.clusters[index + 1].properties.post_seance_yn) {
        //   id = this.state.clusters[index + 1].properties.cluster_post_seance;
        //   break;
        // } else {
        //   id = this.state.clusters[index + 1].properties.cluster_nb;
        //   break;
        // }
      }
    }

    // if (!(new RegExp('bis$')).test(this.state.selectedCluster["Post seance"])) {
    //   id = parseInt(this.state.clusters[id+1].Cluster)
    //   //id = parseInt(this.state.selectedCluster.Cluster) + 1;
    // } else {
    //   id = (parseInt(this.state.selectedCluster.Cluster) + 1);
    // }
    this.setSelectCluster(id);
  };
  previousCluster = () => {
    // let id;
    // if (!(new RegExp('bis$')).test(this.state.selectedCluster["Post seance"])) {
    //   id = parseInt(this.state.selectedCluster.Cluster - 1);
    // } else {
    //   id = "" + (parseInt(this.state.selectedCluster.Cluster) - 1) + " bis";
    // }
    let id;
    for (let index = 0, l = this.state.clusters.length; index < l; index++) {
      if (this.state.clusters[index].properties.cluster_order === this.state.selectedCluster["Ordre cluster"]
        && (this.state.clusters[index].properties.cluster_post_seance === this.state.selectedCluster["Post seance"])) {
        id = index - 1;
        break;
        // if (this.state.clusters[index + 1].properties.post_seance_yn) {
        //   id = this.state.clusters[index + 1].properties.cluster_post_seance;
        //   break;
        // } else {
        //   id = this.state.clusters[index + 1].properties.cluster_nb;
        //   break;
        // }
      }
    }
    this.setSelectCluster(id);
  };

  maskViewOutOfCluster = (clusterPolygon) => {
    let feature = new Feature(clusterPolygon);
    // first look if a mask already exist to change the feature
    if (this.vectorCluster.filters_) {
      this.vectorCluster.filters_[0].feature_ = feature;
    } else {
      let mask = new Mask({ feature: feature, inner: false, fill: new Fill({ color: [255, 255, 255, 0.7] }) })
      this.vectorCluster.addFilter(mask);
    }
  }

  render() {
    let ClusterOjb = this.state.selectedCluster;
    let WorksitesOjb = this.state.worksiteOfCluster;
    let attributes = null;
    let attributesWsInCluster = null;
    if (ClusterOjb) {
      const attributeItem = ClusterOjb;
      attributes = Object.keys(attributeItem).map(function (key) {
        if (!key.includes("Post seance") && !key.includes("Post seance Y/N") && !key.includes("Cluster") && !key.includes("Ordre cluster")) {
          let value;
          if (!key.includes("Post seance")) {
            if (attributeItem[key] != null) {
              value = attributeItem[key].toString().split(",").join(", ")
            } else {
              value = "/";
            }
          }
          if (key.includes("Date") && attributeItem[key] != null) {
            value = attributeItem[key].toString().replace(/^(\d{4})-(\d{2})-(\d{2})Z$/, "$3/$2/$1");
          }
          return <AttributeItem label={key} value={value} key={key} />;
        }
      });
    }

    if (WorksitesOjb.length > 0) {
      attributesWsInCluster = WorksitesOjb.map(ws => {
        let attributeItem = ws.properties;
        if (attributeItem["status_type"] === "PROGRAMME_CLASSIQUE" || attributeItem["status_type"] === "PLANIFIED") {
          let wsAttributeDOM = Object.keys(this.state.attributesWorksiteOfCluster).map(function (key) {
            let keyDB = this.state.attributesWorksiteOfCluster[key];
            let valueViewer = attributeItem[keyDB];
            if (key.includes("Date") && attributeItem[keyDB]) {
              valueViewer = attributeItem[keyDB].toString().replace(/^(\d{4})-(\d{2})-(\d{2})Z$/, "$3/$2/$1");
            }
            return <AttributeItem label={key} value={valueViewer} key={key} />;
          }.bind(this));
          return (<div key={attributeItem["id_ws"]}><table><tbody>{wsAttributeDOM}</tbody></table></div>);
        }
      })
    }

    return (<div className="cockpit-tab__panel">
      <div className="header__menu">
        <Header history={this.props.history} />
      </div>
      <div className="cockpit-tab__header">
        <div>
          <h5>Mailles</h5>
        </div>
        <hr />
        <div className="cockpit-tab__atlas" >
          <button className="cockpit-tab__btn"
            onClick={
              this.previousCluster
            }
          //onClick={this.props.previousWS}
          >
            <i className="fa fa-angle-left" />
          </button>
          <select className="form-control select-css"
            onChange={e => this.selectCluster(e)} value={this.state.selectedCluster ? (this.state.selectedCluster["Post seance Y/N"] ? this.state.selectedCluster["Post seance"] : this.state.selectedCluster["Ordre cluster"]) : 1}>{
              this.state.clusters.map((item, index) =>
              (
                <option value={item.properties.post_seance_yn ? item.properties.cluster_post_seance : item.properties.cluster_order} key={index} data-key={index}>
                  {item.properties.post_seance_yn ?
                    item.properties.id_hyperco_50 ?
                      item.properties.cluster_post_seance + " (ZHC)" : item.properties.cluster_post_seance
                    : item.properties.id_hyperco_50 ?
                      item.properties.cluster_order + " (ZHC)" : item.properties.cluster_order
                  }
                </option>
              ))
            }
          </select>
          <button className="cockpit-tab__btn"
            onClick={
              this.nextCluster
            }
          //onClick={this.props.nextWS}
          >
            <i className="fa fa-angle-right" />
          </button>
        </div>
      </div>
      <hr />
      <div id="map-overview"></div>
      <hr />
      <table className="attributes__container">
        <tbody>{attributes}</tbody>
      </table>
      <hr />
      <div className="attributes__container attributes__worksites" >{
        attributesWsInCluster
      }</div></div>
    );
  }
}

CockpitTab.propTypes = {
  getClusters: PropTypes.func.isRequired,
  clusters: PropTypes.object.isRequired,
  getWorksites: PropTypes.func.isRequired,
  worksites: PropTypes.object.isRequired,
  finishLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  clusters: state.clusters,
  worksites: state.worksites
});

const mapDispatchToProps = dispatch => ({
  getClusters: (urlAPI) => dispatch(getClusters(urlAPI)),
  getWorksites: (urlAPI) => dispatch(getWorksites(urlAPI)),
  finishLoading: () => dispatch(finishLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(CockpitTab);

CockpitTab.contextType = MapContext;