import { GET_CLUSTERS_SUCCESS, GET_CLUSTERS_FAILURE } from "../actions/Types";

const initialState = {
  list: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLUSTERS_SUCCESS:
      return {
        ...state,
        list: action.clusters
      };
    case GET_CLUSTERS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    // case SET_SELECTED_CLUSTERS:
    //   return {
    //     ...state,
    //     selectedCluster: action.payload
    //   };
    default:
      return state;
  }
}
