// GET_ATTRIBUTES
// import { SET_SELECTED_ATTRIBUTES } from "./Types";
import { GET_CLUSTERS_SUCCESS, GET_CLUSTERS_FAILURE } from "./Types";
import { getClustersAPI } from "../API/API";

// export const setSelectedAttribute = (item = "") => {
//   return dispatch => {
//     return dispatch({
//       type: SET_SELECTED_ATTRIBUTES,
//       item
//     });
//   };
// };

// Get all clusters
const getClustersSuccess = clusters => {
  clusters.features.sort((a, b) =>
    a.properties.cluster_order > b.properties.cluster_order ? 1 : -1
  );
  return {
    type: GET_CLUSTERS_SUCCESS,
    clusters
  };
};

const getClustersFailure = error => {
  return {
    type: GET_CLUSTERS_FAILURE,
    error
  };
};

export const getClusters = (url) => dispatch => {
  return getClustersAPI(url).then(
    response => dispatch(getClustersSuccess(response.data)),
    error => dispatch(getClustersFailure(error))
  );
};

// export const setSelectedClusters = (item = "") => {
//   return dispatch => {
//     return dispatch({
//       type: SET_SELECTED_CLUSTERS,
//       item
//     });
//   };
// };
