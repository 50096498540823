import React, { Component } from "react";
import ol_control_LayerSwitcher from "ol-ext/control/LayerSwitcher";
import "../../../../node_modules/ol-ext/control/LayerSwitcher.css";
import { MapContext } from "../Map";

export default class LayerSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuVisible: false
    };
  }

  componentDidMount() {
    const layerSwitcher = new ol_control_LayerSwitcher({
      target: document.getElementById("layerswitcher__content")
    });
    this.context.addControl(layerSwitcher);
  }

  toggleMenu = () => {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible
    });
  };

  render() {
    return (
      <div
        className={
          this.props.isAttributeTabVisible
            ? "layerswitcher__container--active"
            : "layerswitcher__container"
        }
      >
        <button
          className="btn btn-outline-secondary gate__btn--menu"
          type="button"
          onClick={this.toggleMenu}
        >
          <i className="fa fa-ellipsis-h" />
        </button>
        <div
          id="layerswitcher__content"
          className={
            this.state.isMenuVisible
              ? "layerswitcher__content--active"
              : "layerswitcher__content--hidden"
          }
        />
      </div>
    );
  }
}

LayerSwitcher.contextType = MapContext;
