import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { runWithAdal } from "react-adal";
import { authContext } from "./AdalConfig";

const DO_NOT_LOGIN = false;

// import b2cauth from "react-azure-adb2c";

// b2cauth.initialize({
//   instance: "https://login.microsoftonline.com/tfp/",
//   tenant: "xwatch.onmicrosoft.com",
//   signInPolicy: "B2C_1_react_signup",
//   applicationId: "cce6d585-2a83-45d7-b837-ff9a321197c7",
//   cacheLocation: "sessionStorage",
//   scopes: ["https://xwatch.onmicrosoft.com/api/user_impersonation"],
//   redirectUri: "http://localhost:3000",
//   postLogoutRedirectUri: window.location.origin,
//   prompt: "none"
// });

// b2cauth.run(() => {
runWithAdal(
  authContext,
  () => {
    ReactDOM.render(<App />, document.getElementById("root"));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
  },
  DO_NOT_LOGIN
);
serviceWorker.unregister();

// });
