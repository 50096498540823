import OlMap from "ol/Map";
import Projection from "ol/proj/Projection.js";
import OlView from "ol/View";

import ImageLayer from "ol/layer/Image.js";
import ImageWMS from "ol/source/ImageWMS.js";

import VectorSource from "ol/source/Vector.js";
import Cluster from "ol/source/Cluster.js";
import { Vector as VectorLayer } from "ol/layer.js";

import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy.js";
import { ScaleLine, Zoom } from "ol/control.js";

import { Stroke, Style, Fill, Text, Circle, RegularShape } from "ol/style.js";
import { pointerMove } from "ol/events/condition";
import Select from "ol/interaction/Select";
import Point from "ol/geom/Point";
import { getCenter } from 'ol/extent';
import Legend from "ol-ext/legend/Legend";
import LegendControl from "ol-ext/control/Legend";

import { DEVICE_PIXEL_RATIO } from 'ol/has';

export const createLayer = source => {
  if (source.params.service === "wms") {
    return createImgLayer(source);
  } else {
    return createVectorLayer(source);
  }
};

const createImgLayer = source => {
  let params = {
    service: source.params.service,
    version: source.params.version,
    request: source.params.request,
    layers: source.params.layers
  };
  if (source.params.CQL_FILTER) {
    params.CQL_FILTER = source.params.CQL_FILTER
  }
  return new ImageLayer({
    title: source.title,
    source: new ImageWMS({
      url: source.url,
      params: params,
      crossOrigin: "anonymous"
    })
  });
};

const createVectorLayer = source => {
  return new VectorLayer({
    title: source.title,
    source: new VectorSource({
      format: new GeoJSON(),
      url: function (extent) {
        return (
          source.url +
          "service=" +
          source.params.service +
          "&version=" +
          source.params.version +
          "&request=" +
          source.params.request +
          "&typename=" +
          source.params.typename +
          "&outputFormat=" +
          source.params.outputFormat +
          "&srsname=" +
          source.params.srsname +
          "&bbox=" +
          extent.join(",") +
          "," +
          source.params.bbox
        );
      },
      strategy: bboxStrategy
    }),
    style: source.styleAuthorized || source.style.length > 0 ? function (feature) {
      if (feature.get("cluster_maille") != '99')
        return buidStyleObj(feature, source)
    } : [],
    declutter: true,
  });
};

const buildLegendArray = (legendStyleArray, legendArray) => {

  // loop hover the different legend entry
  legendStyleArray.forEach(item => {
    legendArray.push({ title: item.title, "typeGeom": item.typeGeom, style: buidStyleObj(null, null, item.style) });
  });
  return legendArray;
}

const buidStyleObj = (feature, source, style) => {
  if (source != null) {
    if (source.style) {
      style = source.style;
    } else if (source.styleAuthorized && feature.get('status_type') == "AUTORISE") { // style for authorized worksites
      style = source.styleAuthorized;
    } else if (source.styleProgra && (feature.get('status_type') == "PROGRAMME_CLASSIQUE") || (feature.get('status_type') == "PLANIFIED")) { // style for scheduled worksites
      style = source.styleProgra;
    } else {
      console.error("Error of style - undefined feature type for worksites");
      style = [];
    }
  }

  let styleArray = [];
  style.forEach(item => {
    switch (item.type) {
      case "stroke":
        styleArray.push(buildStrokeStyle(item, feature));
        break;
      case "fill":
        styleArray.push(buildFillStyle(item, feature));
        break;
      case "text":
        styleArray.push(buildTextStyle(item, feature));
        break;
      case "hatch":
        let fillStyle = buildFillStyle({ item });
        fillStyle.getFill().setColor(hatchPatern(item));
        styleArray.push(fillStyle);
      default:
        return undefined;
    }
  });
  return styleArray;
};

const buildStrokeStyle = (style, feature) => {
  // console.log(style);
  // console.log(feature);
  return new Style({
    stroke: new Stroke({
      color: style.color,
      width: style.width,
      lineDash: style.lineDash,
      lineDashOffset: style.lineDashOffset,
      lineCap: style.lineCap ? style.lineCap : "round"
    }),
    geometry: feature == null ? null : feature.getGeometry()
  });
};

const buildFillStyle = (style, feature) => {
  return new Style({
    fill: new Fill({
      color: style.color
    }),
    geometry: function (feature) {
      return feature.getGeometry()
    }
  });
};

const buildTextStyle = (style, feature) => {
  if (feature.get("id_ws")) {
    return new Style({
      geometry: function (feature) {
        var coordinates = feature.getGeometry().getExtent();
        return new Point(getCenter(coordinates));
      },
      text: new Text({
        font: "bold 12px Calibri",
        text: isNaN(feature.get("id_ws")) ? feature.get("id_ws") + " (" + feature.get("dura_open") + "j)\n" + feature.get("imp_abrev_fr")
          : feature.get("id_ws").toString() + " (" + feature.get("dura_open") + "j)\n" + feature.get("imp_abrev_fr"),
        fill: new Fill({
          color: "#4a4a4a"
        }),
        stroke: new Stroke({ color: '#ffffff', width: 4 }),
        placement: style.placement,
        overflow: style.overflow
      })
    })
  } else {
    return new Style({
      text: new Text({
        font: "24px Calibri",
        text: feature.get("cluster_maille"),
        fill: new Fill({
          color: "#4a4a4a"
        }),
        placement: Point,
        overflow: true
      })
    })
  }
}

const hatchPatern = (item) => {
  let canvas = document.createElement('canvas');
  let context = canvas.getContext('2d');
  let pixelRatio = DEVICE_PIXEL_RATIO;

  canvas.width = 8 * pixelRatio;
  canvas.height = 8 * pixelRatio;

  // white background
  context.fillStyle = 'white';
  context.fillRect(0, 0, canvas.width, canvas.height);

  // create line
  context.lineWidth = 0.5;
  // context.lineCap = 'round';
  context.strokeStyle = item.color;
  // draw the basic hash image
  context.beginPath();
  context.moveTo(0 * pixelRatio, 8 * pixelRatio);
  context.lineTo(8 * pixelRatio, 0 * pixelRatio);
  context.moveTo(0 * pixelRatio, 0 * pixelRatio);
  context.lineTo(8 * pixelRatio, 8 * pixelRatio);
  context.stroke();

  return context.createPattern(canvas, 'repeat');
};

export let initMap = (appConfig) => {

  // basic control
  const scaleLineControl = new ScaleLine();
  const zoomBtnControl = new Zoom();

  // config project
  const projection = new Projection({
    code: appConfig.map.projection.code,
    units: appConfig.map.projection.units,
    getPointResolution: function (resolution) {
      return resolution;
    }
  });

  // create array with the layers
  const layersArray = [];
  appConfig.layers.forEach(function (arrayItem) {
    layersArray.push(createLayer(arrayItem));
  });

  //set visibilité layers
  layersArray.forEach(layer => {
    if (["Chantiers de Bruxelles Mobilité", "Itinéraires cycable conseillé (ICR)",
      "Zones Hyperco Post Séance"].includes(layer.get("title"))) {
      layer.setVisible(false);
    }
  });

  // add hover if set
  let select = null;
  if (appConfig.style.hover) {
    select = new Select({
      condition: pointerMove,
      style: new Style({
        stroke: new Stroke({
          width: 2,
          color: "red"
        })
      })
    });
  }


  let legendArray = [];
  // add row to the legend
  legendArray = buildLegendArray(appConfig.legend, legendArray);
  // legend control
  const legendControl = new LegendControl({
    legend: new Legend({
      title: 'Légende',
      style: legendArray
    })
  });

  // init openlayer map object
  const olMap = new OlMap({
    target: null,
    controls: [scaleLineControl, zoomBtnControl, legendControl],
    view: new OlView({
      center: appConfig.map.center,
      projection: projection,
      zoom: appConfig.map.zoom
    }),
    layers: layersArray
  });


  // added after and not in the instantiation array of interractions because otherwise map can not be move
  olMap.addInteraction(select);

  // set the feature as selected on the map
  let selectInteraction = new Select();
  selectInteraction.setProperties({ "name": "selectFeature" });
  olMap.addInteraction(selectInteraction);

  return olMap;
};
