import React, { Component } from "react";
import { authContext } from "../AdalConfig";

export default class Home extends Component {

  go2Planification = () => {
    this.props.history.push("/planification");
  };

  go2Programmation = () => {
    this.props.history.push("/programmation");
  }

  go2GMLGenerator = () => {
    this.props.history.push("/gml-generator");
  };
  render() {
    console.log(authContext);
    const appConfig = require("../config/app-config.json");
    const isAdmin = appConfig.access.admin.includes(authContext._user.profile.email);
    const isProgrammation = appConfig.access.programmation.includes(authContext._user.profile.email);
    const isPlannification = appConfig.access.planification.includes(authContext._user.profile.email);
    return (
      <div className="menu__container">
        <li>
          {isAdmin && (
            <ul className="menu__button">
              <a
                href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/UsersManagementMenuBlade/AllUsers"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gestion des utilisateurs
              </a>
            </ul>)}
          {
            (isAdmin || isPlannification) && (
              <ul className="menu__button" onClick={this.go2Planification}>
                Planification
              </ul>
            )
          }
          {(isAdmin || isProgrammation) && (
            <ul className="menu__button" onClick={this.go2Programmation}>
              Programmation
            </ul>)}
          {isAdmin && (
            <ul className="menu__button" onClick={this.go2GMLGenerator}>
              création de GML zone d'alertes
            </ul>)}
        </li>
      </div>
    );
  }
}
