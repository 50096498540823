// GET_ATTRIBUTES
import {
  ADD_TAB,
  REMOVE_TAB,
  START_LOADING,
  FINISH_LOADING
} from "./Types";

export const addTab = (tab = "") => {
  return dispatch => {
    return dispatch({
      type: ADD_TAB,
      tab
    });
  };
};

export const removeTab = index => {
  return dispatch => {
    return dispatch({
      type: REMOVE_TAB,
      index
    });
  };
};

export const startLoading = () => {
  return {
    type: START_LOADING
  };
};

export const finishLoading = () => {
  return {
    type: FINISH_LOADING
  };
};