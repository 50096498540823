import React, { Component } from "react";

export default class AttributeItem extends Component {
  render() {
    return (
      <tr>
        <td className="attribute__column--label">{this.props.label}</td>
        <td className="attribute__column">{this.props.value}</td>
      </tr>
    );
  }
}
