import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import Config from './Config';

//LOCALHOST
// let adalConfig = {
//   tenant: "01b46e2b-022e-4d69-9002-45c6903d56bf",
//   clientId: "06c07cda-2427-481a-aec2-95a48cbdee50",
//   endpoints: {
//     api: "02988f4d-a019-4e72-85de-09872e372a0e"
//   },
//   cacheLocation: "localStorage"
// };
//DEV
let adalConfig = {
  tenant: "01b46e2b-022e-4d69-9002-45c6903d56bf",
  clientId: "109b7372-92cc-49cf-9ed0-d2dd3bfc21df",
  endpoints: {
    api: "02988f4d-a019-4e72-85de-09872e372a0e"
  },
  cacheLocation: "localStorage"
};

//PROD
// let adalConfig = {
//   tenant: "01aac0fa-de60-4003-8e33-19714222d0ca",
//   clientId: "c274d10a-6825-46fa-aaf5-b2d14e0d1e6e",
//   endpoints: {
//     api: "02988f4d-a019-4e72-85de-09872e372a0e"
//   },
//   cacheLocation: "localStorage"
// };

// switch (Config.environment) {
//   case "DEV":
//     adalConfig.clientId = "109b7372-92cc-49cf-9ed0-d2dd3bfc21df";
//     break;
//   case "STAGING":
//     adalConfig.clientId = "d7e4ceef-ecbc-4dc3-8663-cf55b6966082";
//     break;
//   case "PROD":
//     adalConfig.tenant = "01aac0fa-de60-4003-8e33-19714222d0ca";
//     adalConfig.clientId = "c274d10a-6825-46fa-aaf5-b2d14e0d1e6e";
//     break;
//   default:
//     break;
// };

export { adalConfig };

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = () => {
  return withAdalLogin(
    authContext,
    adalConfig.endpoints.api
  )
};
