export const GET_ERRORS = "GET_ERRORS";

export const SET_ATTRIBUTES = "SET_ATTRIBUTES";

export const SET_MAP = "SET_MAP";

// layout
export const ADD_TAB = "ADD_TAB";
export const REMOVE_TAB = "REMOVE_TAB";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

// selected attribute
export const SET_SELECTED_ATTRIBUTES = "SET_SELECTED_ATTRIBUTES";

// clusters
export const GET_CLUSTERS_SUCCESS = "GET_CLUSTERS_SUCCESS";
export const GET_CLUSTERS_FAILURE = "GET_CLUSTERS_FAILURE";

// worksites
export const GET_WORKSITES_SUCCESS = "GET_WORKSITES_SUCCESS";
export const GET_WORKSITES_FAILURE = "GET_WORKSITES_FAILURE";
//export const SET_SELECTED_CLUSTERS = "SET_SELECTED_CLUSTERS";
//export const SET_SELECTED_ATTRIBUTES = "SET_SELECTED_ATTRIBUTES";
